import { TextField, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { initAmplify, getSignedUrl } from "../utils/amplify";
import SettingsIcon from '@mui/icons-material/Settings';

initAmplify();

function FormAcquirer() {
  const regexNumber = /^[0-9]*$/;
  const regexAlphanumeric = /^[a-zA-Z0-9]*$/;

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [amountErrorMsg, setAmountErrorMsg] = useState("");

  const [issuer, setIssuer] = useState("");

  const [reference, setReference] = useState("");
  const [referenceError, setReferenceError] = useState(false);
  const [referenceErrorMsg, setReferenceErrorMsg] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState("");

  const [returnUrl, setReturnUrl] = useState("");
  const [returnUrlError, setReturnUrlError] = useState(false);
  const [returnUrlErrorMsg, setReturnUrlErrorMsg] = useState("");

  const [transactionType, setTransactionType] = useState("ONLINE");
  const [transactionTypeError, setTransactionTypeError] = useState(false);

  const [transactionFlow, setTransactionFlow] = useState("STANDARD");
  const [transactionFlowError, setTransactionFlowError] = useState(false);

  const [mcc, setMcc] = useState("5812");
  const [mccError, setMccError] = useState(false);

  const [amountType, setAmountType] = useState("FIXED");
  const [amountCurrency, setAmountCurrency] = useState("EUR");
  const [expirationPeriod, setExpirationPeriod] = useState(3600);
  const [creditorId, setCreditorId] = useState("100000001");
  const [creditorName, setCreditorName] = useState("The Dutch CookieShop Company N.V.");
  const [countryCode, setCountryCode] = useState("NL");
  const [creditorIban, setCreditorIban] = useState("NL44RABO0123456789");
  const [creditorBic, setCreditorBic] = useState("INGBNL2AXXX");
  const [creditorSubId, setCreditorSubId] = useState(1234);
  const [creditorSubName, setCreditorSubName] = useState("Dutch CookieShop");
  const [creditorSubIban, setCreditorSubIban] = useState("NL44RABO0123456789");
  
  const [socketClosed, setSocketClosed] = useState(false);

  const [valueButton, setValueButton] = useState("Show Extra Fields");
  const [extraFieldsShowed, setExtraFieldsShowed] = useState(false);
  const [extraFieldsButtonIcon , setExtraFieldsButtonIcon] = useState(<ArrowDropDownIcon />)

  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)

  function validateAmount(value) {
    setAmount(value);
    if (!regexNumber.test(value)) {
      setAmountError(true);
      setAmountErrorMsg("Amount must be a number (in eurocents)");
    } else if (value == "") {
      setAmountError(true);
      setAmountErrorMsg("Amount is required");
    } else {
      setAmountError(false);
      setAmountErrorMsg("");
    }
  }

  function showHideExtraFields(){
    if(extraFieldsShowed){
      setExtraFieldsShowed(false)
      setValueButton("Show Extra Fields")
      document.getElementById("advanced-fields").style.display = "none"
      setExtraFieldsButtonIcon(<ArrowDropDownIcon />)
    }else{
      setExtraFieldsShowed(true)
      setValueButton("Hide Extra Fields")
      document.getElementById("advanced-fields").style.display = "inline"
      setExtraFieldsButtonIcon(<ArrowDropUpIcon/>)
    }
  }

  function validateDescription(value) {
    setDescription(value);
    if (value === "") {
      setDescriptionError(true);
      setDescriptionErrorMsg("Description is required");
    } else if (value.length > 35) {
      setDescriptionError(true);
      setDescriptionErrorMsg("Length must be 35 or lower");
    } else {
      setDescriptionError(false);
      setDescriptionErrorMsg("");
    }
  }

  function validateReturnUrl(value) {
    setReturnUrl(value);
    if (value === "") {
      setReturnUrlError(true);
      setReturnUrlErrorMsg("ReturnURL is required");
    } else {
      setReturnUrlError(false);
      setReturnUrlErrorMsg("");
    }
  }

  function validateReference(value) {
    setReference(value);
    if (value == "" || value.length == 0) {
      setReferenceError(true);
      setReferenceErrorMsg("Reference is required");
    } else if (!regexAlphanumeric.test(value)) {
      setReferenceError(true);
      setReferenceErrorMsg("Reference must contain only letters and numbers");
    } else if (value.length > 35) {
      setReferenceError(true);
      setReferenceErrorMsg("Length must be 35 or lower");
    } else {
      setReferenceError(false);
      setReferenceErrorMsg("");
    }
  }

  function handleSubmit() {
    validateAmount(amount.trim());
    validateDescription(description.trim());
    validateReference(reference.trim());
    validateReturnUrl(returnUrl.trim());

    if((amountError || amount === "") || (descriptionError || description === "") || (referenceError || reference === "") || (returnUrlError || returnUrl === "")){
      return false;
    }

    let body = {
        "amount": {
          "amount": 1100,
          "breakdown": {
            "orderAmount": 1000,
            "shippingCost": 100
          },
          "type": "FIXED",
          "currency": "EUR"
        },
        "description": "Cookie",
        "reference": "iDEALpurchase21",
        "expirationPeriod": 3600,
        "transactionType": "ONLINE",
        "transactionFlow": "STANDARD",
        "creditor": {
          "id": "100000001",
          "name": "The Dutch CookieShop Company N.V.",
          "sub": {
            "id": 1234,
            "name": "Dutch CookieShop",
            "iban": "NL44RABO0123456789"
          },
          "cpsp": false,
          "cpspSchemeId": "s8f0kg85Sk",
          "c2cProviderSchemeId": "8f9gss92ks0",
          "countryCode": "NL",
          "iban": "NL44RABO0123456789",
          "bic": "INGBNL2AXXX"
        },
        "expectedDebtor": {
          "userToken": "string",
          "userAgent": "string"
        },
        "requestedCheckoutDetails": {
          "debtorContactDetails": {
            "firstName": true,
            "lastName": true,
            "phoneNumber": true,
            "email": true
          },
          "shippingAddress": true,
          "invoiceAddress": true
        },
        "mcc": "5812",
        "transactionCallbackUrl": "https://checkout.company.com/transaction/webhook/transaction-callback",
        "userTokenCallbackUrl": "https://checkout.company.com/token/webhook/user-token-callback",
        "returnUrl": "https://www.myshop.com/paymentHandling",
        "issuerId": "RABONL2UXXX",
        "subIbanProvided": true
      }

    getSignedUrl().then((signedUrl) => {
      let socketRef;
      if (signedUrl.length) {
        handleShow();
        console.log(signedUrl);
        socketRef = new WebSocket(`${signedUrl}&validation=false`);
        if (socketClosed && socketRef) {
          setSocketClosed(false);
        }

        try {
          socketRef.onopen = () => {
            let paymentURI = {
              ...body,
            };
            // Send data through the WebSocket after the connection is established and the $connect event has returned a 200 status code
            if (socketRef && socketRef.readyState === WebSocket.OPEN) {
              socketRef?.send(JSON.stringify(paymentURI));
            }
          };
        } catch (e) {
          handleClose();
          console.error("An error occured initializing the transaction creation:", e);
        }

        // need to handle this still
        socketRef.onerror = (event) => {
          handleClose();
          console.error("A general error occurred with the WebSocket:", event);
        };

        // wait for message from ITT
        socketRef.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);
            console.log("URL DEVUELTA: " + JSON.stringify(message));
            socketRef.close();
          } catch (e) {
            handleClose()
            console.error("An error occurred while parsing the message:", e);
          }
        };

        socketRef.onclose = (event) => {
          console.log("Closing...");
          handleClose();
          setSocketClosed(true);
        };
      }
    });
  }

  return (
    <Container fluid style={{ width: 70 + "%" }}>
      <div>
        <span label="Create Transaction">
          <Typography variant="h3" gutterBottom>Create Transaction</Typography>
        </span>
      </div>
      <div className="div-margin">
        <Row>
          <Col>
            <TextField
              size="small"
              id="amount"
              required
              error={amountError}
              helperText={amountError ? amountErrorMsg : "In eurocents"}
              label="Amount"
              type="text"
              fullWidth
              value={amount}
              onChange={(e) => validateAmount(e.target.value)}
            />
          </Col>
          <Col>
            <TextField
              size="small"
              id="issuer"
              label="Issuer"
              type="text"
              value={issuer}
              fullWidth
              onChange={(e) => setIssuer(e.target.value)}
            ></TextField>
          </Col>
        </Row>
      </div>
      <div className="div-margin">
        <Row>
          <Col>
            <TextField
              size="small"
              required
              id="reference"
              label="Reference"
              type="text"
              value={reference}
              error={referenceError}
              helperText={referenceErrorMsg}
              fullWidth
              onChange={(e) => validateReference(e.target.value)}
            ></TextField>
          </Col>
          <Col>
            <TextField
              size="small"
              required
              id="description"
              label="Description"
              type="text"
              value={description}
              error={descriptionError}
              helperText={descriptionErrorMsg}
              fullWidth
              onChange={(e) => validateDescription(e.target.value)}
            ></TextField>
          </Col>
        </Row>
      </div>
      <div className="div-margin">
        <Row>
          <Col md="8">
            <TextField
              size="small"
              required
              id="returnUrl"
              label="Return URL"
              type="text"
              value={returnUrl}
              error={returnUrlError}
              helperText={returnUrlErrorMsg}
              placeholder="https://dummy.com"
              fullWidth
              onChange={(e) => validateReturnUrl(e.target.value)}
            ></TextField>
          </Col>
          <Col md="4">
            <FormControlLabel
              control={<Checkbox id="useCPSP" />}
              label="Use a CPSP submerchant"
            />
          </Col>
        </Row>
      </div>
      <div id="advanced-fields" style={{display: "none"}}>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="transactionType"
                label="Transaction Type"
                type="text"
                value={transactionType}
                error={transactionTypeError}
                fullWidth
                onChange={(e) => setTransactionType(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="transactionFlow"
                label="Transaction Flow"
                type="text"
                value={transactionFlow}
                error={transactionFlowError}
                fullWidth
                onChange={(e) => setTransactionFlow(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="mcc"
                label="MCC"
                type="text"
                value={mcc}
                error={mccError}
                fullWidth
                onChange={(e) => setMcc(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="amountType"
                label="Amount Type"
                type="text"
                value={amountType}
                fullWidth
                onChange={(e) => setAmountType(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="amountCurrency"
                label="Amount Currency"
                type="text"
                value={amountCurrency}
                fullWidth
                onChange={(e) => setAmountCurrency(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="expirationPeriod"
                label="Expiration Period"
                type="text"
                value={expirationPeriod}
                fullWidth
                onChange={(e) => setExpirationPeriod(e.target.value)}
              />
            </Col>
          </Row>
        </div>
        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="creditorId"
                label="Creditor Id"
                type="text"
                value={creditorId}
                fullWidth
                onChange={(e) => setCreditorId(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorName"
                label="Creditor Name"
                type="text"
                value={creditorName}
                fullWidth
                onChange={(e) => setCreditorName(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="countryCode"
                label="Country Code"
                type="text"
                value={countryCode}
                fullWidth
                onChange={(e) => setCountryCode(e.target.value)}
              />
            </Col>
          </Row>
        </div>

        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="creditorIban"
                label="Creditor IBAN"
                type="text"
                value={creditorIban}
                fullWidth
                onChange={(e) => setCreditorIban(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorBic"
                label="Creditor BIC"
                type="text"
                value={creditorBic}
                fullWidth
                onChange={(e) => setCreditorBic(e.target.value)}
              />
            </Col>
          </Row>
        </div>

        <div className="div-margin">
          <Row>
            <Col>
              <TextField
                size="small"
                id="creditorSubId"
                label="Creditor Sub Id"
                type="text"
                value={creditorSubId}
                fullWidth
                onChange={(e) => setCreditorSubId(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorSubName"
                label="Creditor Sub Name"
                type="text"
                value={creditorSubName}
                fullWidth
                onChange={(e) => setCreditorSubName(e.target.value)}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                id="creditorSubIban"
                label="Creditor Sub IBAN"
                type="text"
                value={creditorSubIban}
                fullWidth
                onChange={(e) => setCreditorSubIban(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <Row>
        <Col md="3"></Col>
        <Col md="3">
          <Button
            variant="contained"
            onClick={showHideExtraFields}
            endIcon={extraFieldsButtonIcon}
            fullWidth
            color="inherit"
          >
            {valueButton}
          </Button>
        </Col>
        <Col md="3">
          <Button
            variant="contained"
            onClick={handleSubmit}
            endIcon={<SendIcon />}
            color="primary"
          >
            Submit
          </Button>
        </Col>
        <Col md="3"></Col>
      </Row>

      <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered>
        <Modal.Body style={{textAlign: "center"}}>
          <Typography variant="h4">Creating transaction... </Typography>
          <br/>
          <SettingsIcon className="image" fontSize="large"/>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default FormAcquirer;
